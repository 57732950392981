/* Badges */

.badge {
  border-radius: .125rem;
  font-size: 12px;
  font-weight: initial;
  line-height: 1;
  padding: .375rem .5625rem;
  font-weight: $font-weight-medium;
  &.badge-pill {
    border-radius: 10rem;
  }
}

.badge-dark {
  color: #ffffff !important;
}


/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}

/*Badge gradient variations*/
@each $color, $value in $theme-gradient-colors {
  .badge-gradient-#{$color} {
    @include badge-gradient-variations($value);
  }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}