/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;
    .main-panel {
      width: 100%;
      transition: none;
      .content-wrapper {
        padding: 0;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 1.75rem 2.25rem 0rem 2.25rem;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $black;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}

.opacity-50 {
  opacity: 0.5;
  background: #ebedf2;
}

.col-notified {
  max-width: 150px;
  width: 150px;
}

.col-type {
  max-width: 180px;
  width: 180px;

  label.badge {
    min-width: 120px !important;
  }
}

.page-header-checkbox {
  width: 100%;
  display: block !important;
}

.users .select.form-control {
  max-height: 20px;
}

.users-edit .py-1 {
  padding: 4px 0.9375rem;

  .form-control {
    height: 35px;
    line-height: 24px !important;
  }
}

.hidden {
  display: none !important;
}

.accumulated-count {
  font-size: 72px;
  margin-top: 32px;
}

// Users page
.table-responsive select {
  border-radius: 0px;
  outline: none !important;
  border-right: 1px solid #ebedf2 !important;
  border-left: 1px solid #ebedf2 !important;
  color: #000000 !important;
}

.table-responsive select.form-control:disabled {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

select.form-control, .swal2-modal .swal2-content select.swal2-input {
  color: #000000 !important;
}

.DayPicker-Day--birthday {
    background-color: #00bcd4;
    color: white;
  }

div.rmdp-day:has(> span.disabled-select),
.my-calendar div.rmdp-day { 
  pointer-events: none !important; 
}

.highlight.highlight-1 {
  background-color: #00cc33 !important;
  color: #000000 !important;
}

.highlight.highlight-2 {
  background-color: #ff33cc !important;
  color: #000000 !important;
}

.highlight.highlight-3 {
  background-color: #ccff00 !important;
  color: #000000 !important;
}

.highlight.highlight-4 {
  background-color: #00cccc !important;
  color: #000000 !important;
}

.highlight.highlight-5 {
  background-color: #bad0f2 !important;
  color: #000000 !important;
}

.highlight.highlight-6 {
  background-color: #ffe1a9 !important;
  color: #000000 !important;
}

.highlight.highlight-7 {
  background-color: #d2ffd6 !important;
  color: #000000 !important;
}

.highlight.highlight-8 {
  background-color: #d6adbc !important;
  color: #000000 !important;
}

.highlight.highlight-9 {
  background-color: #ff9800 !important;
  color: #000000 !important;
}

.highlight.highlight-11 {
  background-color: #795548 !important;
  color: #fff !important;
}

.highlight-type-pm::before {
    content: "";
    background: #ffffff;
    height: 20px;
    margin-bottom: -2px;
}

.highlight-type-am::after {
    content: "";
    background: #ffffff;
    height: 20px;
    margin-top: -2px;
}

.rmdp-shadow {
    box-shadow: none !important;
}

.my-calendar {
  button.rmdp-arrow-container.rmdp-right,
  button.rmdp-arrow-container.rmdp-left {
      display: none;
  }
}

.bg-primary, .swal2-modal .swal2-actions .swal2-styled.swal2-confirm, .settings-panel .color-tiles .tiles.primary {
    background-color: #5E50F9 !important;
    color: #ffffff;
}

.swal2-container {
  z-index: 999999 !important;
}

.client-name {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer !important;
}

.reporting-table th {
  padding-left: 0px !important;
}

.viewer-table .form-control:disabled {
  background: transparent;
  border: none !important;
  cursor: not-allowed !important;
  outline: none !important;
}

.viewer-table select::-ms-expand {
    display: none !important;
}

.current-date {
  color: #767676;
  font-weight: 400;
  font-size: 12px;
}
.month-summary {
   border: 1px solid #e3e3e3;
}
.month-summary td,
.month-summary th {
  border-right: 1px solid #e3e3e3;
}

.month-summary td.td-marked {
  padding: 4px 20px 4px 10px;
  font-weight: 500;
}

.month-summary td small {
  font-size: 9px;
}

.optionGroup {
    font-weight: bold;
    font-style: italic;
}

.select-search div[class*="-control"] {
  padding: 4px;
  border-color: #ebedf4;
  border-radius: 2px;
}

.data-table-extensions > .data-table-extensions-action > .dropdown button:nth-child(2) {
  /* display: none !important; */
}

#projectSelect {
  min-width: 200px;
}

.high-row {
  line-height: 42px;
}
.block-relative {
    position: relative;
}
.label-absolute {
    position: absolute;
    font-size: 10px !important;
    padding-left: 4px;
    padding-top: 4px;
}

.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
    text-align: center;
}

.data-table-extensions {
  position: relative;
  z-index: 0;
}
.visible-mobile {
  display: none !important;
}

@media (max-width: 991px) {
  .visible-mobile {
    display: block !important;
  }
  .visible-desktop {
    display: none !important;
  }
}

.footer {
  display: none;
}

.ml-end {
  float: right;
}

.my-minus {
  margin-top: -14px;
  margin-bottom: -14px;
}

.employment-edit {
  cursor: pointer;
  margin-left: 16px;
  font-size: 18px;
  margin-top: 4px;
  color: #007bff;
}

#users-table .rdt_TableBody {
  overflow-y: scroll;
      height: 50vh;
      border-left: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
}

.btn-report {
  margin-top: -12px;
}

.data-table-extensions {
  z-index: 1 !important;
}